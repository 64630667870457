import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import("views/auth-views/authentication/login")),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() => import("views/auth-views/authentication/login-1")),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() => import("views/auth-views/authentication/login-2")),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() => import("views/auth-views/authentication/register-1")),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() => import("views/auth-views/authentication/register-2")),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import("views/auth-views/authentication/forgot-password")),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/apps/dashboard/")),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() => import("views/app-views/pages/setting/ChangePassword")),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() => import("views/app-views/pages/setting/EditProfile")),
  },
  // admin=========>
  {
    key: "user.admins",
    path: `${APP_PREFIX_PATH}/list`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminList")),
  },
  {
    key: "user.admins.add",
    path: `${APP_PREFIX_PATH}/add`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.edit",
    path: `${APP_PREFIX_PATH}/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.profile",
    path: `${APP_PREFIX_PATH}/admin/admin-profile/:id`,
    component: React.lazy(() => import("views/app-views/pages/profile/AdminProfile")),
  },

  // user=======>

  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/pages/user/userList")),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/users/add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.profile",
    path: `${APP_PREFIX_PATH}/members-management/user/profile/:id`,
    component: React.lazy(() => import("views/app-views/pages/profile/UserProfile")),
  },
  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/users/details/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserDetails")),
  },

  // -------------------------------------------------------------------
  {
    key: "media.list",
    path: `${APP_PREFIX_PATH}/media/list`,
    component: React.lazy(() => import("views/app-views/pages/media/MediaList")),
  },
  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() => import("views/app-views/pages/setting/SiteSetting")),
  },

  {
    key: "cms.emails.list",
    path: `${APP_PREFIX_PATH}/email-templates/list`,
    component: React.lazy(() => import("views/app-views/pages/email/emailList")),
  },
  {
    key: "cms.emails.edit",
    path: `${APP_PREFIX_PATH}/email-templates/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },
  {
    key: "cms.emails.add",
    path: `${APP_PREFIX_PATH}/email-templates/add`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },

  {
    key: "cms.pages.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/pages/page/pageList")),
  },
  {
    key: "cms.pages.edit",
    path: `${APP_PREFIX_PATH}/pages/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },
  {
    key: "cms.pages.add",
    path: `${APP_PREFIX_PATH}/pages/add`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },

  //Testimonials
  {
    key: "testimonials.list",
    path: `${APP_PREFIX_PATH}/testimonials/list`,
    component: React.lazy(() => import("views/app-views/pages/testimonials/TestimonialsList")),
  },
  {
    key: "testimonials.add",
    path: `${APP_PREFIX_PATH}/testimonials/add`,
    component: React.lazy(() => import("views/app-views/pages/testimonials/AddTestimonials")),
  },
  {
    key: "testimonials.edit",
    path: `${APP_PREFIX_PATH}/testimonials/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/testimonials/AddTestimonials")),
  },

  //Permissions
  {
    key: "permission.list",
    path: `${APP_PREFIX_PATH}/permissions/list`,
    component: React.lazy(() => import("views/app-views/pages/permissions/PermissionList")),
  },

  //Plans
  {
    key: "plans.list",
    path: `${APP_PREFIX_PATH}/plan/list`,
    component: React.lazy(() => import("views/app-views/pages/plan/PlanList")),
  },
  {
    key: "plans.add",
    path: `${APP_PREFIX_PATH}/plan/add`,
    component: React.lazy(() => import("views/app-views/pages/plan/AddPlan")),
  },
  {
    key: "plans.edit",
    path: `${APP_PREFIX_PATH}/plan/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/plan/AddPlan")),
  },

  // Transections
  {
    key: "transections.list",
    path: `${APP_PREFIX_PATH}/transactions/list`,
    component: React.lazy(() => import("views/app-views/pages/transections/TransectionsList")),
  },
  {
    key: "transections.add",
    path: `${APP_PREFIX_PATH}/transections/add`,
    component: React.lazy(() => import("views/app-views/pages/transections/AddTransections")),
  },
  {
    key: "transections.edit",
    path: `${APP_PREFIX_PATH}/transections/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/transections/AddTransections")),
  },
  {
    key: "transections.details",
    path: `${APP_PREFIX_PATH}/transections/details`,
    component: React.lazy(() => import("views/app-views/pages/transections/TransectionDetails")),
  },

  //Subscriptions
  {
    key: "subscriptions.list",
    path: `${APP_PREFIX_PATH}/subscription/list`,
    component: React.lazy(() => import("views/app-views/pages/subscriptions/SubscriptionsList")),
  },

  //Partners
  {
    key: "partners.list",
    path: `${APP_PREFIX_PATH}/partners/list`,
    component: React.lazy(() => import("views/app-views/pages/partners/PartnerList")),
  },
  {
    key: "partners.add",
    path: `${APP_PREFIX_PATH}/partner/add`,
    component: React.lazy(() => import("views/app-views/pages/partners/AddPartner")),
  },
  {
    key: "partners.edit",
    path: `${APP_PREFIX_PATH}/partner/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/partners/AddPartner")),
  },

  //Service
  {
    key: "service.list",
    path: `${APP_PREFIX_PATH}/service/list`,
    component: React.lazy(() => import("views/app-views/pages/service/ServiceList")),
  },

  //enquiry
  {
    key: "enquiry.list",
    path: `${APP_PREFIX_PATH}/enquiry/list`,
    component: React.lazy(() => import("views/app-views/pages/enquiry/EnquiryList")),
  },

  //folders
  {
    key: "folder.list",
    path: `${APP_PREFIX_PATH}/folder/list`,
    component: React.lazy(() => import("views/app-views/pages/folders/FolderList")),
  },
  {
    key: "folder.add",
    path: `${APP_PREFIX_PATH}/folder/add`,
    component: React.lazy(() => import("views/app-views/pages/folders/FolderAdd")),
  },
  {
    key: "folder.edit",
    path: `${APP_PREFIX_PATH}/folder/:id`,
    component: React.lazy(() => import("views/app-views/pages/folders/FolderAdd")),
  },
  {
    key: "folder.edit",
    path: `${APP_PREFIX_PATH}/folder/details/:id`,
    component: React.lazy(() => import("views/app-views/pages/folders/FolderDetails")),
  },

  //Tags
  {
    key: "tags.list",
    path: `${APP_PREFIX_PATH}/tags/list`,
    component: React.lazy(() => import("views/app-views/pages/tags/TagsList")),
  },

  //Fields
  {
    key: "fields.list",
    path: `${APP_PREFIX_PATH}/fields/list`,
    component: React.lazy(() => import("views/app-views/pages/fields/FieldsList")),
  },

  //Items
  {
    key: "items.list",
    path: `${APP_PREFIX_PATH}/items/list`,
    component: React.lazy(() => import("views/app-views/pages/items/ItemsList")),
  },
  {
    key: "items.add",
    path: `${APP_PREFIX_PATH}/item/add`,
    component: React.lazy(() => import("views/app-views/pages/items/ItemAdd")),
  },
  {
    key: "items.edit",
    path: `${APP_PREFIX_PATH}/item/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/items/ItemEdit")),
  },

  // Campaigns
  {
    key: "campaigns.list",
    path: `${APP_PREFIX_PATH}/campaign/list`,
    component: React.lazy(() => import("views/app-views/pages/campaign/campaignList")),
  },
];
